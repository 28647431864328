export const environment = {
  redirectUrl: 'https://2fafe.datastage.gpi-pulse.com',
  graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
  aadOauth2BEApi: 'https://2fabe.datastage.gpi-pulse.com/api',
  azurePolicies: {
    clientId: 'df1661bd-529d-4dd0-aa95-2a3e768410f3',
    tenantId: '8a52c601-c47a-458c-ab79-0b28ae0adcef',
    authority: 'https://login.microsoftonline.com/',
    scopes: ['user.read'],
  },
  apiConfig: {
    scopes: ['api://e4a4421b-8911-471d-a7ea-22c12a7b4a3e/api.scope'],
    uri: 'https://2fabe.datastage.gpi-pulse.com',
  },
  graphApiConfig: {
    scopes: ['user.Read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
