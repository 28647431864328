<div *ngIf="isUserLoggedIn" class="center">
  <mat-card>
    <mat-card-header *ngIf="!isShowSpinner">
      <h1>Welcome {{ userProfile?.givenName }}</h1>
    </mat-card-header>
    <mat-card-content *ngIf="!isShowSpinner">
      <mat-list>
        <mat-list-item>User: {{ userProfile?.displayName }}</mat-list-item>
        <mat-list-item>Title: {{ userProfile?.jobTitle }}</mat-list-item>
        <mat-list-item>Email: {{ userProfile?.mail }}</mat-list-item>
      </mat-list>

      <div style="margin-top: 10px; margin-bottom: 10px" *ngIf="authCode">
        <button
          mat-stroked-button
          color="primary"
          aria-label="Copy authorization code"
          [cdkCopyToClipboard]="authCode"
        >
          {{ authCode }}
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
    </mat-card-content>
    <mat-card-footer *ngIf="!isShowSpinner">
      <button
        mat-raised-button
        color="accent"
        class="full-width"
        (click)="getAccessCode()"
      >
        Generate Access Code
      </button>

      <button
        mat-raised-button
        color="warn"
        class="full-width"
        style="margin-top: 5px"
        (click)="logout()"
      >
        Logout
      </button>
    </mat-card-footer>

    <mat-spinner *ngIf="isShowSpinner"></mat-spinner>
  </mat-card>
</div>
