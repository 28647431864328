import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfileModel } from './user-profile-model';
import { environment } from 'src/environments/environment';
import { UserAuthInfoModel } from './user-auth-info-model';

@Injectable({
  providedIn: 'root',
})
export class AadAuthService {
  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getUserInfo() {
    return this.http.get<UserProfileModel>(environment.graphEndpoint);
  }

  getAccessCode() {
    return this.http.get<UserAuthInfoModel>(`${environment.aadOauth2BEApi}/User/GetUserAccessCode`);
  }
}
