import { Component, OnInit } from '@angular/core';
import { AadAuthService } from '../aad-auth.service';
import { UserProfileModel } from '../user-profile-model';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isUserLoggedIn: Boolean = false;
  userProfile?: UserProfileModel;
  authCode = '';
  isShowSpinner = false;

  constructor(
    private aadAuthService: AadAuthService,
    private authService: MsalService
  ) {}

  ngOnInit(): void {
    this.isShowSpinner = true;

    this.aadAuthService.isUserLoggedIn.subscribe(
      (s) => {
        this.isUserLoggedIn = s;
        this.authCode = '';
        if (this.isUserLoggedIn) {
          this.getUserInfo();
        }
        this.isShowSpinner = false;
      },
      (err) => {
        console.log(err);
        this.isShowSpinner = false;
      }
    );
  }

  private getUserInfo() {
    this.isShowSpinner = true;
    this.aadAuthService.getUserInfo().subscribe(
      (user) => {
        this.userProfile = user;
        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
      }
    );
  }

  getAccessCode() {
    this.isShowSpinner = true;
    this.aadAuthService.getAccessCode().subscribe(
      (authInfo) => {
        this.authCode = authInfo.authCode;
        this.isShowSpinner = false;
      },
      (err) => {
        console.log(err);
        this.isShowSpinner = false;
      }
    );
  }

  logout() {
    this.aadAuthService.isUserLoggedIn.next(false);

    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUrl,
    });
  }
}
