<mat-toolbar color="primary" class="mat-elevation-z4">
  <mat-toolbar-row class="center">
    <span>Azure active directory OAuth 2.0</span>
  </mat-toolbar-row>
</mat-toolbar>

<div class="center card-top">
  <button
    mat-raised-button
    extended
    color="accent"
    *ngIf="!isUserLoggedIn"
    (click)="login()"
  >
    Click here to Login into your Microsoft account
  </button>
</div>

<router-outlet></router-outlet>
